<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Contact Us</h2>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-content">
          <h4>Channels to reach Akshayam Organics</h4>
          <table class="table">
            <tbody>
              <tr>
                <td><i class="fa fa-4x fa-phone"></i></td>
                <td>+65 809 809 89</td>
              </tr>
              <tr>
                <td><i class="fa fa-4x fa-whatsapp"></i></td>
                <td>+65 809 809 89</td>
              </tr>
              <tr>
                <td><i class="fa fa-4x fa-envelope"></i></td>
                <td>akshayamproducts@gmail.com</td>
              </tr>
              <tr>
                <td><i class="fa fa-4x fa-map-marker"></i></td>
                <td>
                  Aarupadai Impex Pvt Ltd
                  <br />
                  Singapore
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>